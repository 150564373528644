<template>
  <div>
    <template v-if="$auth.check({ patients: 'phi' })">
      {{ patient.dob | moment('L') }}
    </template>
    <v-chip
      v-else
      label
      x-small
      dark
      color="grey darken-1"
      class="text-overline me-1 px-2"
      v-text="$t('protected')"
    />
    <age-bracket-chip
      v-if="pediatricAge"
      :bracket="ageBracket"
      :age="pediatricAge"
      icon
    />
  </div>
</template>
<script>
  export default {
    components: {
      AgeBracketChip: () => import("@/components/AgeBracketChip.vue"),
    },
    props: {
      patient: {
        type: Object,
        default: () => ({}),
        required: true,
      },
      ageDate: {
        type: [Date, String],
        default: null,
      },
      ageBracket: {
        type: String,
        default: null,
      },
    },
    data () {
      return {
        age_units: 'years',
      }
    },
    computed: {
      pediatricAge () {
        if (this.ageDate) {
          let age = this.$moment(this.ageDate).diff(this.patient.dob, 'years')
          if (age < 2) {
            age = this.$moment(this.ageDate).diff(this.patient.dob, 'months')
            if (age < 1) {
              age = this.$moment(this.ageDate).diff(this.patient.dob, 'days')
              return `${age} ${this.$tc('day', age)}`
            } else {
              return `${age} ${this.$tc('month', age)}`
            }
          } else if (age < 18) {
            return `${age} ${this.$tc('year', age)}`
          } else {
            return false
          }
        } else {
          return false
        }
      },
    },
  }
</script>
